import { plantPlanner, regenerateColumns } from './plans';

const navigationSelector = '.plants-navigation';
let numberOfDays = 1;
let firstVisibleColumnIndex = 0;
let baseDate = new Date();

const getFirstVisibleColumnIndex = () => firstVisibleColumnIndex < totalColumnCount() ? firstVisibleColumnIndex : 0;
export const setNumberOfDays = (days) => { numberOfDays = days; };
export const triggerColumnRegeneration = () => regenerateColumns();
export const getNumberOfDays = () => numberOfDays;
export const setBaseDate = (date) => { baseDate = date; };

export const getMaxVisibleColumnCount = () => ($(window).width() <= 768 ? 1 : (totalColumnCount() || 256));

export function getCurrentlyVisibleColumnCount() {
//  console.log('getMaxVisibleColumnCount: ', getMaxVisibleColumnCount());
//  console.log('getFirstVisibleColumnIndex: ', getFirstVisibleColumnIndex());
//  console.log('getMaxVisibleColumnCount() - getFirstVisibleColumnIndex(): ', getMaxVisibleColumnCount() - getFirstVisibleColumnIndex());
  return Math.min(getMaxVisibleColumnCount(), totalColumnCount() - getFirstVisibleColumnIndex());
};

const plantColumns = () => $('#content-plan-form > #plants-info > div');

const numberOfPlants = () => plantColumns().length;

const totalColumnCount = () => plantColumns().length * numberOfDays;
export function visibleColumnIndexToPlantIndex(index) {
//console.log('index: ', index);
//console.log('getCurrentlyVisibleColumnCount: ', getCurrentlyVisibleColumnCount());
//console.log('firstVisibleColumnIndex: ', firstVisibleColumnIndex);
//console.log('getFirstVisibleColumnIndex: ', getFirstVisibleColumnIndex());
//console.log('numberOfPlants: ', numberOfPlants());
//console.log('result: ', (index + getFirstVisibleColumnIndex()) % numberOfPlants());
 return (index + getFirstVisibleColumnIndex()) % numberOfPlants();
}
export const visibleColumnIndexToDay = (index) => Math.floor((index + getFirstVisibleColumnIndex()) / numberOfPlants());
export function visibleColumnIndexToDate(index) {
  const date = new Date(baseDate);
  date.setDate(date.getDate() + visibleColumnIndexToDay(index));
  return date;
}
export function dayAndPlantIdToVisibleColumnIndex(day, plantId){
  const plantIndex = plantColumns().filter(`[data-plant-id="${plantId}"]`).data("column");
  //if (plantIndex == -1) debugger;
  //console.log('plantIndex: ', plantIndex);
  //console.log('day: ', day);
  //console.log('numberOfPlants: ', numberOfPlants());
  //console.log('plantIndex + day * numberOfPlants(): ', plantIndex + day * numberOfPlants());
  return (plantIndex + day * numberOfPlants()) - getFirstVisibleColumnIndex();
};
export const visibleColumnIndexToPlantId = (index) => plantColumns().eq(visibleColumnIndexToPlantIndex(index)).attr('data-plant-id');
export function numberOfColumnsToShow () {
  //
  //console.log('plantColumns().length: ', plantColumns().length);
  //console.log('numberOfDays: ', numberOfDays);
  //console.log(`window width: ${$(window).width()}`);
  //console.log(`totalColumnCount: ${totalColumnCount()}`);
  //console.log(`getFirstVisibleColumnIndex: ${getFirstVisibleColumnIndex()}`);
  return ($(window).width() <= 768 ? 1 : (totalColumnCount() - getFirstVisibleColumnIndex()));

} 
const isNavigationVisible = () => !$(navigationSelector).hasClass('hidden');

const needNavigation = () => totalColumnCount() > getMaxVisibleColumnCount();

const plantIdsFromPlanner = () => plantColumns().map((i, div) => div.getAttribute('data-plant-id')).toArray();

const canMoveRight = () => (getFirstVisibleColumnIndex() + getMaxVisibleColumnCount()) < totalColumnCount();

const canMoveLeft = () => getFirstVisibleColumnIndex() !== 0;

function setPlants() { plantPlanner.refetchEvents(); }

function setButtonVisibility(selector, canMove) {
  const button = $(selector);

  if (canMove) {
    if (button.hasClass('hidden')) { button.removeClass('hidden'); }
  } else if (!button.hasClass('hidden')) {
    button.addClass('hidden');
  }
}

function setButtonsVisibility() {
  setButtonVisibility('#plants-navigation-right', canMoveRight());
  setButtonVisibility('#plants-navigation-left', canMoveLeft());
}

function moveRight() {
  if (!canMoveRight()) {
    return;
  }

  firstVisibleColumnIndex = getFirstVisibleColumnIndex() + 1;
  setButtonsVisibility();
  setPlants();
}

function moveLeft() {
  if (!canMoveLeft()) {
    return;
  }

  firstVisibleColumnIndex = getFirstVisibleColumnIndex() - 1;
  setButtonsVisibility();
  setPlants();
}

function initCallbacks() {
  $('#plants-navigation-right').on('click', () => {
    moveRight();
  });

  $('#plants-navigation-left').on('click', () => {
    moveLeft();
  });
}

function updateNavigationVisibility() {
  if (needNavigation()) {
    if (!isNavigationVisible()) { $(navigationSelector).removeClass('hidden'); }
    setButtonsVisibility();
  } else if (isNavigationVisible()) { $(navigationSelector).addClass('hidden'); }
}

export function initNavigation() {
  if ($('.plants-navigation').length === 0) {
    $('.fc-row.fc-widget-header').append(
      '<div class="plants-navigation hidden">'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-left">&#60;</div></div>'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-right">&#62;</div></div>'
      + '</div>',
    );
    initCallbacks();
  }
  updateNavigationVisibility();
}

export function updateNavigation() {
  firstVisibleColumnIndex = 0;

  updateNavigationVisibility();
}

export function getVisiblePlantIds() {
  const visiblePlantIds =  [];
  for (let columnId = 0; columnId < getMaxVisibleColumnCount(); columnId++) {
    const visiblePlantId = visibleColumnIndexToPlantId(columnId);
    if (visiblePlantId < 0) debugger;
    if (visiblePlantId) {
      visiblePlantIds.push(visiblePlantId);
    }
  }
  //console.log('visiblePlantIds: ', visiblePlantIds);
  return [...new Set(visiblePlantIds)];
}
