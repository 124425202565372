$(document).ready(() => {
  $(() => {
    setTimeout(() => {
      $('#flash').html('');
    }, 30000);
  });

  $(document).on('DOMSubtreeModified', 'div[id=flash], #notifications-errors, #errors', () => {
    setTimeout(() => {
      $('div[id=flash], #notifications-errors, #errors').html('');
    }, 30000);
  });
});

export const showFlashMessage = function (message, type, selector) {
  type = typeof type !== 'undefined' ? type : 'danger';
  selector = typeof selector !== 'undefined' ? selector : '#flash';

  const messageHtml = `<div class='alert-${type}'>${message}</div>`;

  $(selector).html(messageHtml);
};
window.showFlashMessage = showFlashMessage;
